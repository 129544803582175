import supportedLanguages from './supported-languages';
import defaultGlobalClientConfig, {
  IGlobalClientConfig,
} from '../../../default/config/GlobalClientConfig/GlobalClientConfig';
import {
  AuthenticationFlowTypes,
  FeaturedEventsCarouselType,
} from '../../../../config/Enums/App';
import Routes from '../../../../config/Routes/Routes';
import { MsisdnRequiredness } from '../../../../config/Enums/MsisdnRequiredness';

const GlobalClientConfig: IGlobalClientConfig = {
  ...defaultGlobalClientConfig,
  siteTitle: 'Arena Esports',
  clientName: 'Arena Esports',
  partnerCopyrightName: 'Etisalat',
  copyrightName: 'Swarmio Media',
  clientOrganizationSiteUrl: 'https://swarmio.media/',
  authenticationFlowType: AuthenticationFlowTypes.Carousel,
  featuredEventsCarouselType: FeaturedEventsCarouselType.Centered,
  numberOfEventsToShowOnHomePage: 6,
  numberOfServersToShowOnHomePage: 2,
  accountSetupRoute:
    process.env.VITE_APP_SHOW_SUBSCRIPTIONS &&
    process.env.VITE_APP_SHOW_SUBSCRIPTIONS.toLowerCase() === 'true'
      ? Routes.subscribe
      : Routes.connectGameAccounts,
  nav: {
    orientation: 'vertical',
  },
  currency: {
    showOnlyPoints: true,
  },
  internationalization: {
    defaultLng: 'en',
    supportedLngs: supportedLanguages,
  },
  eventDiscordLink: 'https://discord.gg/wVf9XXs55M',
  msisdn: {
    format: 'AE',
    requiredness: MsisdnRequiredness.ABSENT,
  },
  countryCode: 784,
  storeBanner: (storePartnertId: string | undefined) =>
    `https://store-assets.arenaesports.ae/store-banners/headers/${storePartnertId}/header-image.png`,
  colors: {
    ...defaultGlobalClientConfig.colors,
    darkGrey: '#1a1a1a',
    lightGrey: '#c1c0c0',
    black: '#0f0f0f',
    white: '#f2f2f2',
    primary: '#37c6d1',
    secondary: '#0f0f0f',
    danger: '#f73422',
    success: '#02aa4b',
    warning: '#e59b02',
    dark: '#1a1a1a',
    light: '#c1c0c0',
  },
};

export default GlobalClientConfig;
